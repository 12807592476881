import img1 from '../../Assets/projects/project1.png'
import img2 from '../../Assets/projects/project2.png'
import img3 from '../../Assets/projects/project3.png'
import img4 from '../../Assets/projects/project4.png'
import img5 from '../../Assets/projects/project5.png'


const projectsArray = [
    {   projectName:'Salon Website Project – Salon Miurangana',
        desc:"I designed and developed a responsive website for Salon Miurangana, aimed at enhancing their online presence and providing customers with easy access to their services, location, and contact information. The website features an elegant design tailored to reflect the salon's branding.",
        year:"2024",
        role:'Front-end Developer (Freelancer)',
        complteStatus:true,
        url:"https://salon-miurangana.com/",
        tech:'React.js',
        img:img1
    },

    {   projectName:'Ongoing Project: Sun in Moon Hotel Website',
        desc:"I am currently developing a website for Sun in Moon Hotel as a freelance project. The goal is to create an informative and visually appealing website that effectively showcases the hotel's rooms, amenities, and services, providing a seamless user experience for potential guests.",
        year:"2024",
        role:'Front-end Developer (Freelancer)',
        complteStatus:false,
        tech:'React.js',
        img:img2
    },

    {   projectName:'Ongoing Project: Shoresh Herbal Official Site',
        desc: "An elegant e-commerce platform showcasing premium herbal products. Features include a vibrant homepage, categorized product listings, trending items, customer reviews, and an eco-friendly focus. Designed for a seamless shopping experience with a natural and sustainable theme.",
        year:"2025",
        role:'Web Developer (Freelancer)',
        complteStatus:true,
        url:"https://ridmi1999212.wixsite.com/shoresh",
        tech:'Wix',
        img:img5
    },

    {   projectName:'Front-End Developer at Datagraphy',
        desc:"As a front-end developer at Datagraphy, I contributed to the development and enhancement of their official website. My role involved creating dynamic, interactive SVG animations using D3.js to improve data visualizations and provide users with an engaging experience. I also worked closely with the design team to implement responsive and visually appealing interfaces.",
        year:"2023 - 2024",
        role:'Front-end Developer , D3.js Developer',
        complteStatus:true,
        url:"https://www.datagraphy.studio/",
        tech:'React,js, D3.js',
        img:img3
    },

    {   projectName:'Tour Packages Website - Serene Lanka',
        desc:"Developed a user-friendly website for Serene Sri Lanka to showcase detailed tour packages with stunning photos of the country’s landscapes and cultural landmarks. The site features interactive itineraries, a captivating photo gallery, and a responsive design for seamless browsing on any device, delivering an engaging experience for travelers.",
        year:"2024",
        role:'Front-end Developer',
        complteStatus:true,
        url:"https://travelsl.ridmiperera.online/",
        tech:'WordPress',
        img:img4
    }
]

export {
    projectsArray
}