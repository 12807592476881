import React from 'react'
import './myExperience.css'

const MyExperience = () => {
  return (
    <div className='app__experience app__wrapper app__padding'>
      <h1 className='headtext__bebas' data-aos="fade-up" data-aos-duration="2000" id='experience'>My Experience</h1>
      <div className='app__experience__desc' >
        <div className='app__experience__desc-title' data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
          <h1 className='subhead__manrope'>Junior Front-End Developer</h1>
          <p className='p__manrope'>Aug 2023 — Nov 2024 </p>
        </div>
        <div data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
          <p className='p__manrope green' >Datagraphy, Sri Lanka</p>
          <p className='p__manrope'>Develop interactive, data-driven animations using D3.js to enhance user engagement and data visualization.</p>
          <p className='p__manrope'>Expertly manipulate SVG elements to create dynamic, responsive graphics for web applications.</p>
          <p className='p__manrope'>Developed a sophisticated web application that enables users to visualize their data through a selection of animated D3 data visualizations.</p>
        </div>
        <div className='app__experience__desc-two'> 
          <div className='app__experience__desc-title' data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
            <h1 className='subhead__manrope'>Intern - Web Developer</h1>
            <p className='p__manrope'>Oct 2022 - Aug 2023</p>
          </div>
          <div data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
            <p className='p__manrope green'>Ollcode Pvt Ltd., Sri Lanka</p>
            <p className='p__manrope'>Design and implement high-quality, reusable React.js components to enhance user interfaces.</p>
            <p className='p__manrope'>Collaborate with design teams to create responsive and accessible web pages, ensuring cross device compatibility.</p>
            <p className='p__manrope'>Contribute to front-end architecture discussions, offering insights on best practices and emerging trends in React.js development.</p>
          </div>
        </div>
      </div>     
    </div>
  )
}

export default MyExperience
